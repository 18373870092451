/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";
import loadable from "@loadable/component";

import { useSpecialCursor } from "../common/SpecialCursor/SpecialCursor";
import { useRavensbourneContext } from "../context/RavensbourneContext";
import * as styles from "./Layout.module.scss";
import Transition from "./Navigation/transition";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { ServiceWorkerRefresh } from "./ServiceWorker/ServiceWorkerRefresh";

const Search = loadable(() => import("./Search/Search"));
const Menu = loadable(() => import("./Menu/Menu"));

const Layout = ({ children, location, ...props }) => {
  const { menuOpen, searchOpen, setSearchOpen, setMenuOpen } =
    useRavensbourneContext();
  const { onCursorReset } = useSpecialCursor();
  const [mounted, setMounted] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Some logic for switching between black and white footer
  const isAlternate = (path) => {
    if (
      path === "/" ||
      path.includes("/about") ||
      path.includes("/aboutravensbourne") ||
      path.includes("/contact")
    ) {
      return false;
    }

    const find = ["/students/", "/courses/", "/features/", "/tags/"].find((r) =>
      path.startsWith(r)
    );

    return !!find;
  };

  const withHero = (path) => {
    if (path === "/") {
      return true;
    }

    return ["/courses/"].find((r) => path.startsWith(r));
  };

  const cls = classNames(styles.main, {
    [styles.push]: searchOpen || menuOpen,
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setSearchOpen(false);
    setMenuOpen(false);
    onCursorReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setMenuOpen, setSearchOpen]);

  return (
    <>
      {mounted ? (
        <>
          <Search />
          <Menu />
        </>
      ) : null}
      <Header
        siteTitle={data.site.siteMetadata.title}
        forceBackground={!withHero(location.pathname)}
        location={location}
      />
      <div className={cls}>
        <Transition location={location}>
          <main>{children}</main>
        </Transition>
        <Footer alternate={isAlternate(location.pathname)} />
      </div>
      <ServiceWorkerRefresh />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Layout };
