import React, {
  useRef,
  useEffect,
  createContext,
  useContext,
  useCallback,
  useState,
} from "react";
import classNames from "classnames";
import { motion, useSpring, useTransform, useMotionValue } from "framer-motion";
import loadable from "@loadable/component";

import { PaperCanvas } from "../PaperCanvas/PaperCanvas";
import * as styles from "./SpecialCursor.module.scss";

const Sketch = loadable.lib(() => import("./src/cursorSketch"), { ssr: false });

function lerp(v0, v1, t) {
  return v0 * (1 - t) + v1 * t;
}

const SpecialCursorContext = createContext({
  mousePos: {
    x: -100,
    y: -100,
  },
  onCursorHighlight: () => {},
  onCursorReset: () => {},
  handlers: {
    onMouseEnter: () => {},
    onMouseLeave: () => {},
  },
  hide: () => {},
  show: () => {},
});

const CURSOR_DEFAULT = 10;
const CURSOR_LARGE = 25;

const SpecialCursorContainer = ({ children }) => {
  // const [hidden, setHidden] = useState(false);
  const mousePosX = useMotionValue(0);
  const mousePosY = useMotionValue(0);
  const canvasPosX = useMotionValue(0);
  const canvasPosY = useMotionValue(0);
  const opacity = useSpring();
  const [display, setDisplay] = useState(false);
  const [highlightActive, setHighlightActive] = useState(false);
  const isHighlighting = useRef(false);

  const circleSize = useSpring(CURSOR_DEFAULT);
  const circleSizeClamp = useTransform(circleSize, [0, 200], [0, 200]);

  const onPaperSetup = useCallback(
    (paper, canvas, onSetup) => {
      onSetup(paper, canvas, {
        size: circleSizeClamp,
        highlighting: isHighlighting,
      });
    },
    [circleSizeClamp]
  );

  const onCursorHighlight = () => {
    circleSize.set(CURSOR_LARGE);
    isHighlighting.current = true;
    setHighlightActive(true);
  };

  const onCursorReset = () => {
    circleSize.set(CURSOR_DEFAULT);
    isHighlighting.current = false;
    setHighlightActive(false);
    onShow();
  };

  const onHide = () => {
    // setHidden(true);
    opacity.set(0);
  };

  const onShow = () => {
    // setHidden(false);
    opacity.set(1);
  };

  useEffect(() => {
    if (display) {
      document.documentElement.classList.add("with-cursor");

      const cb = (e) => {
        mousePosX.set(e.clientX - 50);
        mousePosY.set(e.clientY - 50);
      };

      window.addEventListener("mousemove", cb, { passive: true });

      return () => {
        window.removeEventListener("mousemove", cb);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display]);

  useEffect(() => {
    let frameId;
    const update = () => {
      canvasPosX.set(lerp(canvasPosX.get(), mousePosX.get(), 0.2));
      canvasPosY.set(lerp(canvasPosY.get(), mousePosY.get(), 0.2));

      frameId = requestAnimationFrame(update);
    };
    update();

    return () => {
      cancelAnimationFrame(frameId);
    };
  }, []);

  useEffect(() => {
    setDisplay(!window.matchMedia("(pointer: coarse)").matches);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle("with-cursor", !highlightActive);
  }, [highlightActive]);

  // const canvasPosX = useSpring(mousePosX, {
  //   damping: 1000,
  //   stiffness: 700,
  // });
  // const canvasPosY = useSpring(mousePosY, {
  //   damping: 1000,
  //   stiffness: 700,
  // });

  const value = {
    mousePos: {
      x: mousePosX,
      y: mousePosY,
    },
    onCursorHighlight,
    onCursorReset,
    handlers: {
      onMouseEnter: onCursorHighlight,
      onMouseLeave: onCursorReset,
    },
    hide: onHide,
    show: onShow,
  };

  const canvasCls = classNames(
    styles.wrapper,
    styles.canvas,
    {
      [styles.active]: highlightActive,
    }
    // {
    //   [styles.hidden]: hidden,
    // }
  );

  const staticCls = classNames(canvasCls, styles.changeless);

  return (
    <SpecialCursorContext.Provider value={value}>
      {children}

      {display && (
        <Sketch>
          {(sketch) => (
            <PaperCanvas
              onFrame={sketch.onFrame}
              onLoad={(paper, canvas) =>
                onPaperSetup(paper, canvas, sketch.onSetup)
              }
              onResize={sketch.onResize}
              onMouseMove={sketch.onMouseMove}
            >
              {(ref) => (
                <>
                  <motion.div
                    style={{
                      x: canvasPosX,
                      y: canvasPosY,
                      opacity,
                    }}
                    className={canvasCls}
                  >
                    <canvas width="200" height="200" ref={ref} />
                  </motion.div>
                  <motion.div
                    style={{
                      x: mousePosX,
                      y: mousePosY,
                      opacity,
                    }}
                    width="200"
                    height="200"
                    className={staticCls}
                  ></motion.div>
                </>
              )}
            </PaperCanvas>
          )}
        </Sketch>
      )}
    </SpecialCursorContext.Provider>
  );
};

export const useSpecialCursor = () => useContext(SpecialCursorContext);

export { SpecialCursorContainer };
