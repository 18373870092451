import React from "react";
import classNames from "classnames";
import { ReactComponent as RULSvg } from "./rul.svg";
import * as styles from "./RULLogo.module.scss";

const RULLogo = ({ className, isTop, location }) => {
  const link = location.pathname.split("/")[1];
  const showLowerLogo = link === "" || link === "courses";

  const cls = classNames(styles.header, {
    className,
    [styles.lower]: !isTop || !showLowerLogo,
  });

  return (
    <>
      <RULSvg className={cls} />
    </>
  );
};

export default RULLogo;
